<template>
  <b-card class="shop-wallet">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="walletRef"
          :columns="walletColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/shop/wallet'"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BCard,
  },
  data() {
    return {
      walletColumns: [
        {
          label: 'Transaction Id',
          field: 'transaction_alias',
        },
        {
          label: 'Description',
          field: 'transaction_desc',
        },
        {
          label: 'Type',
          field: 'type',
          rField: 'type_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.type === 'C' ? 'Credit' : 'Debit'),
        },
        {
          label: 'Amount',
          field: 'amount',
          rField: 'amount_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            if (props.stores_details) {
              return `${props.stores_details.currency_symbol} ${props.amount}`
            }
            return 'NA'
          },
        },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
